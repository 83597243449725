import * as React from "react"
import { Helmet } from 'react-helmet';

//styles
import "../styles/css/styles.css"

import defaults from "../components/dafaults"
import config from "../utils/config"

import Form from "../components/shared/Form"
import defaultsData from "../utils/formStructure"

import SpecifiSearch from "../components/SpecificSearch";


// components
import Header from "../components/Header"
import Footer from "../components/Footer"
import Search from "../components/Search/index"
import Banner from "../components/Banner"
import Slider from "../components/Slider"
import Icon from "../components/Icon"

import bannerDesktop from "../../assets/images/desktop.main-banner.jpg"
import bannerMobile from "../../assets/images/mobile.main-banner.jpg"

import applestore from "../../assets/images/icons/apple-store.svg"
import googleplay from "../../assets/images/icons/google-play.svg"

export default function Home() {

  const [ lancamentos, setLancamentos ] = React.useState(null)
  const [ dicas, setDicas ] = React.useState(null)
  const [ banners, setBanners ] = React.useState(null)
  const [ specificSearchCustom, setSpecificSearchCustom ] = React.useState(null);
  const [ loadingMessage, setLoadingMessage ] = React.useState('');

  React.useLayoutEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    if(lancamentos == null) {
    fetch(`${defaults.api.rest}lancamento?_embed`, requestOptions)
      .then(response => response.json())
      .then(result => {
          setLancamentos(result)
      })
      .catch(error => console.log('error', error));
    }

      
      if(dicas == null) {
        fetch(`${defaults.api.rest}posts?postType=dado-tecnico&_embed`, requestOptions)
        .then(response => response.json())
        .then(result => {
            setDicas(result)
        })
        .catch(error => console.log('error', error));
      }


      if(banners == null) {
        fetch(`${defaults.api.rest}custom-banner?category-banner=678`, requestOptions)
        .then(response => response.json())
        .then(result => {
            setBanners(result)
        })
        .catch(error => console.log('error', error));
      }

  })

  const lancamentoConfig = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const dicasConfig = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return(
    <>
      <Helmet>
        <title>Home - Wega</title>
        <link rel="shortcut icon" href={config.favicon} type="image/x-icon"></link>
      </Helmet>
      <Header />
      <Search />
      <section className="main-banner">
        <Banner banners={banners} />
      </section>
      <section className="lancamentos">

        <div className="container">
            {/* <div>
              <SpecifiSearch 
                setSpecificSearchCustom={setSpecificSearchCustom}
                endpoint="https://admin.wegamotors.com/wp-json/wp/v2/lancamento?_embed&search=" 
                setLoadingMessage={setLoadingMessage}
              />
              <p className="search__loading-text">{loadingMessage}</p>
            </div> */}

            <h2><span>Últimos</span> Lançamentos</h2>
            <div className="navigation">
              <a href="/lancamentos">Ver todos lançamentos</a>
            </div>
        </div>
        <div className="container">
          {/* <Slider sliders={specificSearchCustom != null && specificSearchCustom.length > 1 && specificSearchCustom || lancamentos} buttonLabel="Mais detalhes >" config={lancamentoConfig} path="/lancamentos" /> */}
          <Slider sliders={lancamentos} buttonLabel="Mais detalhes >" config={lancamentoConfig} path="/lancamentos" />
        </div>
      </section>
      <section className="newsletter">
        <div className="container">
          <h2>Catálogo Wega no seu celular</h2>
          <p>Baixe nosso aplicativo e tenha sempre nosso catálogo atualizado com os últimos lançamentos! Disponível para Android e iOS.</p>
          <p>Quer receber o link em seu e-mail? Preencha no campo abaixo:</p>
          {/* <div className="fields">
            <input type="text" />
            <button>RECEBER</button>
          </div> */}
          <Form type="Newsletter" structure={defaultsData.Newsletter} />
          <div className="icons">
            <Icon link="https://apps.apple.com/br/app/wega-motors/id1451224639" title="Wega na Apple Store" icon={applestore} />
            <Icon link="https://play.google.com/store/apps/details?id=br.com.ideia2001.CatalogoWegaMotors&pli=1" title="Wega na Google Play" icon={googleplay} />
          </div>
        </div>
      </section>
      <section className="dicas">
        <div className="container">
            <h2><span>Dicas</span> Técnicas</h2>
            <div className="navigation">
              <a href="/dica">Ver todas as dicas</a>
            </div>
        </div>
        <div className="container">
          <Slider sliders={dicas} buttonLabel="Saiba Mais" config={dicasConfig} path="/dicas" />
        </div>
      </section>
      <Footer />
    </>
  )
}

export const Head = () => <title>Wega Motors</title>