import * as React from "react"
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css";

// styles
import "../styles/css/styles.css"

const Banner = (props) => {

    const [ listBanner, setListBanners ] = React.useState(null)

    function reverseArr(input) {
        var ret = new Array;
        for(var i = input.length-1; i >= 0; i--) {
            ret.push(input[i]);
        }
        return ret;
    }
    
    React.useLayoutEffect(() => {

        if(props?.banners && props.banners.length > 0) {
            if(listBanner == null)
                setListBanners(reverseArr(props.banners))
        }
    })


    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Slider {...settings}>
            {
                listBanner?.map(banner => (
                    <div className="slide">
                        <a href={banner?.acf?.link_do_banner} target={banner?.acf?.nova_janela && banner?.acf?.nova_janela[0] == "sim" ? "_blank" : "_self"} title={banner.title.rendered}>
                            <img src={window.innerWidth > 991 ? banner?.acf?.banner_desktop : banner?.acf?.banner_mobile} alt={banner.title.rendered} />
                        </a>
                    </div>
                ))
            }
        </Slider>
    )
}

export default Banner